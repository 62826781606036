module.exports = {
  topBar: {
    home: "Home",
    introduce: "Project",
    application: "Application",
    repurchase: "Repurchase",
    conce: "Philosophy",
    profit: "Profit",
    video: "Video",
    contact: "Contact Us",
    download: "Download",
    downloadApp: "Download"
  },
  home: {
    des2: "DeTok Is the First Decentralized",
    des: "Short Video Platform in the World",
    des1: "Linking Global Users With Videos",
    // des2: "Link global users with videos",
    btn1: "Introduction",
    btn2: "White Paper",
    btn3: "Download",
    btn4: "Download Beta"
  },
  introduce: {
    title: "Our Project",
    des: " is the world’s first decentralized short-video platform, linking the global users with videos. Based on the main principle of direct value exchanges between creators, viewers, advertisers and reviewers, DeTok combines short video with blockchain technology to realize commercial applications such as recording copyright on blockchain, copyright confirmation, transaction and authorization, and creates a platform for users to make money by swiping videos in their fragmented time.",
    des1: "DeTok",
    btnText: "Learn about"
  },
  application: {
    title: "Application Scenarios",
    modal1: {
      title: "Video Viewers",
      des: "Watch videos to get rewards",
      des1: "Share videos to get rewards",
      des2: "Invite friends to get rewards",
      des3: "Complete tasks to get rewards",
    },
    modal2: {
      title: "Video Creators",
      des: "Post videos to get rewards",
      des1: "Invite friends to get rewards",
      des2: "Sell/rent out videos to get rewards",
    },
    modal3: {
      title: "Video Buyers",
      des: "Pay rewards to buy videos",
      des1: "Sell/rent out videos to get rewards",
    },
    modal4: {
      title: "Advertisers",
      des: "Pay rewards to buy videos and run ads",
      des1: "Deposit rewards to open stores in DeTok Mall"
    },
    modal5: {
      title: "Video Reviewers",
      des: "Deposit rewards to become reviewers",
      des1: "Review videos to get rewards",
      des2: "Review comments to get rewards",
    },
    btnText: "Short Video"
  },
  repurchase: {
    title: "Repurchase Scenarios",
    modal1: {
      des: "Through providing services of buying gifts and rewarding videos, our platform shares income with users."
    },
    modal2: {
      des: "Video review requires depositing rewards."
    },
    modal3: {
      des: "Rewards should be paid to buy or rent videos. Our platform shares income with video owners.",
      des1: ""
    },
    modal4: {
      des: "Advertisers pay rewards to run ads. Our platform shares income with video creators.",
      des1: ""
    },
    modal5: {
      des: "To open store in DeTok Mall requires depositing rewards. Mall management fee should be paid for with rewards.",
      des1: ""
    },
    modal6: {
      des: "Value-added services (such as VIP service, prop purchase, ad-free service) require to be paid for with rewards.",
      des1: ""
    }
  },
  conce: {
    title: "DeTok Philosophy",
    modal1: {
      title: "Copyright Ensured",
      des: "Video ownership is permanently recorded on blockchain to ensure the video copyright is traceable and cannot be tampered with."
    },
    modal2: {
      title: "Non-Fungible",
      des: "Video owners can sell or rent out video copyright through blockchain. The copyright transfer can be finished in an instant."
    },
    modal3: {
      title: "Marketization",
      des: "With rewards, it is more convenient to promote various market-oriented behaviors such as video production, circulation, trading and consumption."
    },
    modal4: {
      title: "Convenient",
      des: "As a short video platform, DeTok provides convenient interfaces and services for various applications."
    },
    modal5: {
      title: "Reliable",
      des: "Video copyright is confirmed by blockchain and cannot be tampered with."
    },
    modal6: {
      title: "More Competitive",
      des: "With community autonomy, users can apply to be video reviewers, which lowers cost greatly."
    }
  },
  profit: {
    title: "Profit Model",
    modal1: {
      title: "DIFI mining and profit repurchase",
      des: "Live broadcast reward split",
      des1: "Advertising Flow realization",
      des2: "Share income with sale",
      des3: "Loan service handling fees",
      des4: "Other",
    },
    modal2: {
      title: "Allocation plan",
      // des: "40% of participating contributors in the DOK: station (ecological incentives) 1% of DOK:Detok Chain personal consultants and institutional consultants 5% of DOK: strategic partners 34% of DOK:difi mining users About 20% of the DOK: teams",
      des: "40% of participating contributors in the DOK: station",
      des1: "(ecological incentives)",
      des2: "1% of DOK:Detok Chain personal consultants and",
      des3: "institutional consultants",
      des4: "5% of DOK: strategic partners",
      des5: "34% of DOK:difi mining users",
      des6: "About 20% of the DOK: teams",
    }
  },
  video: {
    title: "Popular Videos"
  },
  download: {
    des: "Have Fun and Make Money",
    downloadIos: "Download",
    inDevelopment: "developing",
    downloadAndroid: "Download"
  },
  contact: {
    title: "Contact Us",
    official: "Official Links:",
    ceo: "CEO Liao Wang:",
    by: "A project by Atoshi",
    service: "Tec servicer:",
    serviceDes: "Only Tenical Issues"
  },
  toast: "Stay tuned for IOS development"
}