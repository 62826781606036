module.exports = {
  topBar: {
    nav1: "首页",
    nav2: "商业模式",
    nav3: "规划特点",
    nav4: "重塑场景",
    nav5: "应用场景",
    nav6: "联系我们",
    downloadApp: "下载APP"
  },
  home: {
    des: "背靠XXXXX用户的Web3+短视频平台",
    des1: "用Web3赋能短视频，用短视频链接全球用户",
    des2: "（原子链资本投资，原子链实时总用户：XXXXX）",
    btn1: "Android版本",
    btn2: "苹果iOS(简化版)",
    btn3: "国内版下载",
    btn4: "下载内测版"
  },
  introduce: {
    title: "项目介绍",
    des: "是全球首个区块链短视频平台，用视频链接全球用户，以创作者、观众、广告商和审核官的直接价值交换为主要原则，将短视频与区块链技术结合，实现产权上链，确权、交易，授权等商业应用，打造一个利用碎片化时间刷刷视频就可以赚钱的视频平台。",
    des1: "乐唰",
    btnText: "去了解"
  },
  businessModel: {
    title: "颠覆三十观的商业模式",
    modal1: {
      title: "观众",
      des: "用户：观看/分享视频/邀请注册获赠积分通证",
      des1: "传播分享视频获取奖励",
      des2: "邀请新用户获取奖励",
      des3: "完成平台任务获取奖励",
      details: [
        "1)、Web3作为新兴数字产业之一，发展前景无限",
        "2)、手机挖取积分通证，简单好用",
        "3)、用户持有平台积分通证之后，为了让积分通证更有价值，不断推广看视频，用户忠诚度和粘性更高，乐此不疲",
        "4)、裂变速度快，邀请新用户奖积分通证，用户自发推荐更多用户下载",
        "5)、流量正循环，用户越来越多",
        "6)、用户越多，共识越强，积分通证越有价值"
      ]
    },
    modal2: {
      title: "创作者",
      des: "每个视频都有版权保护，创作者可以将视频出售，获得收入；之后每次视频再转售，创作者都会收取视频交易价格的10%作为版权税；无论谁拥有视频，创作者都会有该视频打赏或者广告收入的10%作为版权税",
      des1: "邀请新用户获取奖励",
      des2: "出租出售视频获取奖励",
    },
    modal3: {
      title: "广告商",
      des: "投放广告，不用联系广告销售人员，去中心化，APP直接竞拍广告,简单高效还直接",
      des1: "出租出售视频获取奖励",
    },
    modal4: {
      title: "审核官",
      des: "用户抵押积分通证，审核视频/广告等内容获取积分通证奖励，视频内容用户说了算",
      des1: "质押奖励开通商城售卖商品"
    },
    modal5: {
      title: "视频购买者",
      des: "省去拍视频的烦恼，直接购买视频，获得流量，加速成为大V，赚取广告收益及其他流量变现",
      des1: "审核视频获取奖励",
      des2: "审核评论获取奖励",
    },
    modal6: {
      title: "流量拥有者",
      des: "省去拍视频的烦恼，购买更多精彩视频，增加流量，越来越火，赚取广告收益及其他流量变现",
    },
    modal7: {
      title: "平台方",
      des: "用户越来越多，日活越来越高，积分通证越有价值，打赏收益分成，广告分成，视频买卖分成，有足够的资金发展更好的用户体验",
    },
    btnText: "短视频"
  },
  repurchase: {
    title: "规划应用场景",
    modal1: {
      des: "购买礼物，打赏视频，用户和平台分成"
    },
    modal2: {
      des: "审核视频需要抵押积分通证"
    },
    modal3: {
      des: "购买和租赁视频需要使用积分通证，视频出租和出售者和平台分成",
      des1: "视频出租和出售者和平台分成"
    },
    modal4: {
      des: "广告主使用积分通证投放广告，创作者和平台获得积分通证分成",
      des1: "创作者和平台获得奖励分成"
    },
    modal5: {
      des: "开通商城需要抵押积分通证，商城管理费用要用积分通证支付",
      des1: "商城管理费用要用奖励支付"
    },
    modal6: {
      des: "VIP用户服务费，购买道具，去广告等增值服务需要支付积分通证",
      des1: "去广告等增值服务需要支付奖励"
    }
  },
  plan: {
    title: "乐唰规划特点",
    modal1: {
      title: "版权上链",
      des: "短视频、直播版权上链，版权确权不可篡改、永久保存、可追溯，可链上转让，透明，高效公正低成本完成版权交易。"
    },
    modal2: {
      title: "去中心化",
      des: "采用去中心化的方式，视频由用户自主审核，视频内容用户说了算，让用户作为平台的主人。广告插入不用联系广告销售，直接竞拍广告投放权。"
    },
    modal3: {
      title: "每个视频都是NFT",
      des: "用户上传的视频，将会带有上链编码，成为一个NFT,持有者出租/出售视频版权，瞬间完成版权买卖交割。"
    },
    modal4: {
      title: "运营成本更低",
      des: "用户抵押积分通证，通过测试，成为审核员；审核视频是否应该通过，防止暴力及其他不良视频播出，直接省去某些短视频平台成千上万的审核员费用；目前我们一个视频刚发布，审核任务就被抢走，审核时间一般控制在几分钟以内。"
    }
  },
  scene: {
    title: "将重塑场景",
    modal1: {
      title: "重塑社交场景",
      des: "视频社交，更加直观视频打赏，用户社交更加多元 乐友圈集合朋友圈，微博等功能 建立粉丝群，万人群，实时聊天"
    },
    modal2: {
      title: "重塑购物场景",
      des: "视频（直播）展示，更生动 边看边买，随心购物更自在"
    },
    modal3: {
      title: "重塑营销场景",
      des: "视频营销，生动直观 视频广告，形式多样化"
    },
    modal4: {
      title: "重塑本地生活场景",
      des: "视频动态展示，提高决策效率"
    },
    modal5: {
      title: "重塑游戏场景",
      des: "随玩随走，更低的用户体验成本"
    }
  },
  profit: {
    title: "盈利模式",
    modal1: {
      title: "DIFI挖矿与利润回购",
      des: "直播打赏平台分成",
      des1: "广告流量变现",
      des2: "主播带货分成收益",
      des3: "借贷服务手续费",
      des4: "其他",
    },
    modal2: {
      title: "分配方案",
      des: "40%的DOK:站内参与贡",
      des1: "献者(生态激励)",
      des2: "1%的DOK:Detok链个人",
      des3: "顾问和机构顾问",
      des4: "5%的DOK:战略合作伙伴",
      des5: "34%的DOK:difi挖矿用户",
      des6: "20%的DOK:团队",
    }
  },
  video: {
    title: "热门视频"
  },
  download: {
    des: "乐唰好玩又赚钱",
    downloadIos: "IOS 下载",
    inDevelopment: "IOS开发中",
    downloadAndroid: "安卓手机下载"
  },
  contact: {
    title: "联系我们",
    official: "官方链接：",
    ceo: "CEO廖望：",
    by: "原子链旗下项目",
    service: "技术客服：",
    serviceDes: "仅限技术问题"
  },
  toast: "IOS开发中，敬请期待"
}