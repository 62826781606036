export function langSet() {
  let locale = ((document.referrer.indexOf('detok') > -1) || (document.referrer.indexOf('facebook') > -1) || (document.referrer.indexOf('twitter') > -1) || document.URL.indexOf('detok') > -1) ? 'en' : 'ch';
  return locale;
}


export function upFixed (num, fix) {
  // num为原数字，fix是保留的小数位数
  let result = '0'
  if (Number(num) && fix > 0) { // 简单的做个判断
    fix = +fix || 2
    num = num + ''
    if (/e/.test(num)) { // 如果是包含e字符的数字直接返回
      result = num
    } else if (!/\./.test(num)) { // 如果没有小数点
      result = num + `.${Array(fix + 1).join('0')}`
    } else { // 如果有小数点
      num = num + `${Array(fix + 1).join('0')}`
      let reg = new RegExp(`-?\\d*\\.\\d{0,${fix}}`)
      let floorStr = reg.exec(num)[0]
      if (+floorStr >= +num) {
        result = floorStr
      } else {
        let floorNumber = +floorStr + +`0.${Array(fix).join('0')}1`
        let point = /\./.test(floorNumber + '') ? '' : '.'
        let floorStr2 = floorNumber + point + `${Array(fix + 1).join('0')}`
        result = reg.exec(floorStr2)[0]
      }
    }
  }
  return result
}