<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app',
  created() {
    //如果是移动端打开 跳转到https://ch.m.atoshi.org/#/
    // var sUserAgent = navigator.userAgent.toLowerCase();
    var isMobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    if(isMobile)
    {
      this.setHtmlSizeMobile();
      // window.location.href = "https://ch.m.atoshi.org/#/";
    }
    else
    {
      this.setHtmlSizePc();
    }

  },
  methods: {
    // equipment()
    // {
    //   var u = window.navigator.userAgent;
    //   var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android终端或者uc浏览器
    //   var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    //   var isWeixin = !!/MicroMessenger/i.test(u);
    //   if (isWeixin) {
    //     this.show = true;
    //     this.showDialog = "weixin";
    //     return ;
    //   }
    //   if(isAndroid)
    //   {
    //     window.location.href = enums.wangzhuanAppAddress;
    //   }
    //   if(isiOS){
    //       let _this = this;
    //       Toast({
    //           message:'IOS版本研发中,敬请期待!',
    //           duration:5000,
    //           onClose:function()
    //           {
    //             _this.show = false;
    //           }
    //       });
    //       return ;
    //   }
    // },
    setHtmlSizeMobile() {
      //获取页面宽度
      var winW = document.body.clientWidth;
      //根据页面宽度设置html字体大小。
      document.documentElement.style.fontSize = winW / (750 / 100) + "px";
      //当页面大小发生改变,重新修正rem为新窗口尺寸的3.75分之一
      window.onresize = function() {
        document.documentElement.style.fontSize = (document.body.clientWidth) / (750 / 100) + "px";
      }
    },
    setHtmlSizePc() {
      //获取页面宽度
      var winW = document.body.clientWidth;
      //根据页面宽度设置html字体大小。
      document.documentElement.style.fontSize = winW / (1920 / 20) + "px";
      //当页面大小发生改变,重新修正rem为新窗口尺寸的3.75分之一
      window.onresize = function() {
        document.documentElement.style.fontSize = (document.body.clientWidth) / (1920 / 20) + "px";
      }
    },
  }
}
</script>


<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

router-view{
  width: 100%;
  height: 100%;
  min-height: 100%;
}
</style>
